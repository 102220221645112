import { Grid, ListSubheader, MenuItem, Select, Typography } from '@mui/material';
import { useGetAgingGroups } from 'api/admin/use-get-aging-groups';
import { ascend, prop, sortWith } from 'ramda';

function SelectionItem({ id, name, devicePrefix }: { id: string; name: string; devicePrefix: string }) {
	return (
		<Grid container spacing={1} sx={{ maxWidth: '100%', minWidth: '600px', gap: 1 }}>
			<Grid item sx={{ width: '130px' }}>
				<Typography>{devicePrefix}</Typography>
			</Grid>
			<Grid item sx={{ width: '30px' }}>
				<Typography>{id}</Typography>
			</Grid>
			<Grid item sx={{ flex: 1 }}>
				<Typography>{name}</Typography>
			</Grid>
		</Grid>
	);
}

export default function GroupSelection({ name, value, onChange, useUUID = false, cellSpecId = '', disabled = false }) {
	const { data = [], isLoading } = useGetAgingGroups();
	const groups = sortWith(
		[ascend(prop('devicePrefix')), ascend(prop('ordinal'))],
		cellSpecId !== '' ? data.filter((dta) => dta?.[useUUID ? 'cellSpecExternalId' : 'cellSpecId'] === cellSpecId) : data
	);

	return (
		<>
			{!isLoading && (
				<Select
					id={name}
					name={name}
					disabled={disabled}
					labelId={name}
					value={value}
					onChange={onChange}
					renderValue={(value) => {
						const selected = data.find((dta) => dta?.[useUUID ? 'externalId' : 'id'] === value);
						return (
							<Typography>
								ID: {selected?.id}, Name: {selected?.name}
							</Typography>
						);
					}}>
					<ListSubheader>
						<SelectionItem id="ID" name="Group Name" devicePrefix="Device Prefix" />
					</ListSubheader>
					{groups.map((group) => (
						<MenuItem value={group[useUUID ? 'externalId' : 'id']} key={group.id}>
							<SelectionItem id={group.id} name={group.name} devicePrefix={group.devicePrefix} />
						</MenuItem>
					))}
				</Select>
			)}
		</>
	);
}
