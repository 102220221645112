import { Alert, Snackbar, Portal } from '@mui/material';
import { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import getErrorMessage from 'util/handle-catch-error';
import { useDeleteWorkOrder } from 'pages/admin/work-order/api/use-delete-work-order';

export default function DeleteWorkOrderButton({ id, disabled = false, ...props }) {
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [error, SetError] = useState<string | null>(null);
	const deleteWorkOrder = useDeleteWorkOrder();

	const handleDialogOpen = () => {
		setIsDialogOpen(true);
	};

	const handleDialogClose = () => {
		setIsDialogOpen(false);
	};

	const handleSubmitDelete = async () => {
		try {
			await deleteWorkOrder.mutateAsync(id);
			handleDialogClose();
			setIsSnackbarOpen(true);
		} catch (err) {
			const message = getErrorMessage(err);
			SetError(message);
		}
	};

	const handleCloseSnackbar = () => {
		setIsSnackbarOpen(false);
	};

	return (
		<>
			<GridActionsCellItem disabled={disabled} {...props} onClick={handleDialogOpen} />
			<Dialog
				maxWidth="lg"
				fullWidth
				open={isDialogOpen}
				onClose={handleDialogClose}
				aria-labelledby="alert-dialog-title"
				onKeyDown={(e) => e.stopPropagation()}
				aria-describedby="alert-dialog-description">
				<DialogTitle id="alert-dialog-title">Delete work order</DialogTitle>
				<DialogContent>
					Are you sure you want to delete work order ID #{id}
					{!!error && (
						<DialogContentText id="alert-dialog-error" sx={{ color: 'error.main' }}>
							{error}
						</DialogContentText>
					)}
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSubmitDelete} color="primary" autoFocus>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
			<Portal>
				<Snackbar
					open={isSnackbarOpen}
					autoHideDuration={3000}
					onClose={handleCloseSnackbar}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
					<Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
						Work order ID #{id} deleted
					</Alert>
				</Snackbar>
			</Portal>
		</>
	);
}
