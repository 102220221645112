import { Box, Button, Typography } from '@mui/material';
import { DataGridPro, GridToolbarColumnsButton, useGridApiRef } from '@mui/x-data-grid-pro';
import { EditToolbar } from 'components/data-grid/toolbar/EditToolbar';
import {
	defaultSortModel,
	columns,
	getRowClass,
	getDeliverables,
	getTreeDataPath,
	groupingColDef,
	defaultBoxSx,
	workOrderSx,
} from './consts';
import { useGet } from './api/use-get-work-order';
import CreateWorkOrderButton from './create-work-order';
import { useState } from 'react';

export default function WorkOrderTable() {
	const gridApiRef = useGridApiRef();
	const { data = [], isLoading } = useGet();
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<>
			<Typography variant="h4" color="primary">
				Work Orders
			</Typography>
			<Box sx={defaultBoxSx}>
				<DataGridPro
					apiRef={gridApiRef}
					loading={isLoading}
					rows={data}
					getRowClassName={getRowClass}
					treeData
					getTreeDataPath={getTreeDataPath}
					groupingColDef={groupingColDef}
					isGroupExpandedByDefault={() => isExpanded}
					disableColumnMenu={true}
					columns={columns}
					sx={workOrderSx}
					slots={{
						noRowsOverlay: () => <Typography align="center">Data not found</Typography>,
						toolbar: () => (
							<Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
								<EditToolbar
									children={
										<>
											<Button
												variant="outlined"
												sx={{ minWidth: 135 }}
												onClick={() => {
													setIsExpanded(!isExpanded);
												}}>
												{isExpanded ? 'Collapse' : 'Expand'} All
											</Button>
											<GridToolbarColumnsButton />
										</>
									}
								/>
								<CreateWorkOrderButton />
							</Box>
						),
					}}
					initialState={{
						columns: {
							columnVisibilityModel: {
								id: false,
								devicePrefix: false,
								group: false,
								testId: false,
								deleted: false,
								createdAt: false,
								createdBy: false,
								deviceName: false,
								protocolStep: false,
							},
						},
						sorting: {
							sortModel: defaultSortModel,
						},
						filter: {
							filterModel: { items: [{ field: 'deleted', operator: 'is', value: 'false' }] },
						},
					}}
					slotProps={{
						toolbar: {
							showQuickFilter: true,
							quickFilterProps: { debounceMs: 500 },
						},
					}}
					getDetailPanelContent={getDeliverables}
					rowHeight={80}
					getDetailPanelHeight={({ row }) => 'auto'}
				/>
			</Box>
		</>
	);
}
