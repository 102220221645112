import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Snackbar,
	TextField,
	Typography,
	Portal,
} from '@mui/material';
import getErrorMessage from 'util/handle-catch-error';
import { usePostWorkOrderDeliverables } from 'api/work-order/use-post-work-order-deliverables';
import { uploadToS3 } from 'api/work-order/upload-to-S3';

const defaultBoxSx = {
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	gap: 1,
	width: '100%',
	'> .MuiTypography-root': { minWidth: '160px', maxWidth: '160px' },
	'> .MuiFormControl-root': {
		flex: 1,
	},
};

export default function UploadDeliverablesForm({
	id,
	onClose,
	open,
}: {
	id: string;
	onClose: () => void;
	open: boolean;
}) {
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
	const postWorkOrderDeliverables = usePostWorkOrderDeliverables();
	const {
		control,
		formState: { errors },
		setError,
		getValues,
		watch,
		clearErrors,
		trigger,
		handleSubmit,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			fileNumber: '',
			files: [],
		},
	});
	const fileArr = watch('files');

	const onSubmit = async () => {
		clearErrors('root.serverError');
		try {
			const { fileNumber, files } = getValues();
			const s3destinations = await postWorkOrderDeliverables.mutateAsync({
				id: id,
				payload: { numDeliverables: Number(fileNumber) },
			});
			const uploadPromises = s3destinations.map((d, index) => {
				const file = files[index];
				const formData = new FormData();

				Object.keys(d.presignedPost.fields).forEach((key) => {
					formData.append(key, d.presignedPost.fields[key]);
				});
				formData.append('file', file);

				return uploadToS3({ url: d?.presignedPost?.url, payload: formData });
			});
			await Promise.all(uploadPromises);
			setIsSnackbarOpen(true);
			onClose();
		} catch (err: unknown) {
			const message = getErrorMessage(err);
			setError('root.serverError', {
				message: message,
			});
		}
	};

	const handleCloseSnackbar = () => {
		setIsSnackbarOpen(false);
	};

	return (
		<>
			<Dialog open={open} onClose={onClose} fullWidth onKeyDown={(e) => e.stopPropagation()}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogTitle>Upload deliverable(s)</DialogTitle>
					<DialogContent sx={{ width: '100%' }}>
						<Box sx={{ pb: 2 }}>
							<Box sx={defaultBoxSx}>
								<Typography color="primary">Number of data files that you would like to upload</Typography>
								<FormControl fullWidth>
									<Controller
										control={control}
										name="fileNumber"
										rules={{
											required: "'Number' is required",
											validate: {
												minLength: (value) => {
													const floatValue = parseFloat(value);
													return (
														(Number.isInteger(floatValue) && floatValue >= 1) ||
														"'Number' must be an integer larger than 1"
													);
												},
											},
										}}
										render={({ field: { name, onChange, value }, fieldState: { error } }) => (
											<TextField
												id={name}
												name={name}
												value={value}
												onChange={(e) => {
													onChange(e.target.value);
													trigger('files');
												}}
												variant="outlined"
												label="Number"
											/>
										)}
									/>
								</FormControl>
							</Box>
							<Box>{errors?.fileNumber && <Typography color="error">{errors?.fileNumber?.message}</Typography>}</Box>
						</Box>
						<Box sx={{ pb: 2 }}>
							<Button component="label" variant="contained" tabIndex={-1}>
								Upload file(s)
								<Controller
									control={control}
									name="files"
									rules={{
										required: "'File(s)' are required",
										validate: (value) =>
											value.length === parseFloat(getValues().fileNumber) ||
											'Number of selected files does not match the indicated number',
									}}
									render={({ field: { name, onChange, value }, fieldState: { error } }) => (
										<input
											id={name}
											name={name}
											type="file"
											multiple
											hidden
											onChange={(event) => {
												onChange(event.target.files);
											}}
										/>
									)}
								/>
							</Button>
							<Box>
								{Array.from(fileArr).map((f: any, idx) => (
									<Typography key={idx}>{f?.name}</Typography>
								))}
							</Box>
							<Box>{errors?.files && <Typography color="error">{errors?.files?.message}</Typography>}</Box>
						</Box>
					</DialogContent>
					<DialogActions sx={{ width: '100%', height: '60px' }}>
						<Box
							sx={{
								textOverflow: 'ellipsis',
								overflow: 'hidden',
								height: '100%',
								width: 'calc(100% - 120px)',
								flex: 1,
							}}>
							{errors.root?.serverError && <Typography color="error">{errors.root?.serverError?.message}</Typography>}
						</Box>
						<Box>
							<Button onClick={onClose}>Cancel</Button>
							<Button type="submit">Submit</Button>
						</Box>
					</DialogActions>
				</form>
			</Dialog>
			<Portal>
				<Snackbar
					open={isSnackbarOpen}
					autoHideDuration={3000}
					onClose={handleCloseSnackbar}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
					<Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
						Successfully uploaded!
					</Alert>
				</Snackbar>
			</Portal>
		</>
	);
}
