import {
	Drawer,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Divider,
	IconButton,
} from '@mui/material';
import { LibraryBooks, Memory, CloudDownload, Help, History } from '@mui/icons-material';

import { Box } from '@mui/system';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactElement } from 'react';
import { useAdminVisible } from 'hooks/useAdminVisible';
import { useUserStore } from 'user-store';
import AccountMenu from 'components/AccountMenu';
import { testDataRoute, adminRoute } from 'routes/const';
import TestProgramsNavButton from './TestProgramsNavButton';

export interface navItemType {
	name: string;
	pathName: string;
	icon: ReactElement;
	text: string;
}

const studioNavItems = [
	{
		name: 'data-sheet',
		pathName: '/studio/data-sheet',
		icon: <LibraryBooks />,
		text: 'Smart Datasheet',
	},
	{
		name: 'simulation',
		pathName: '/studio/simulation',
		icon: <Memory />,
		text: 'Simulation',
	},
	{
		name: 'simulation-history',
		pathName: '/studio/simulations',
		icon: <History />,
		text: 'Simulation History',
	},
	{
		name: 'cell-data-download',
		pathName: testDataRoute,
		icon: <CloudDownload />,
		text: 'Cell Data Download',
	},
] as navItemType[];

function WorkOrderButton({ handleClick }) {
	return (
		<nav aria-label="external partner navigation items">
			<List data-cy="externalNav" sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
				<ListItemButton onClick={handleClick} sx={{ px: 2, py: 1 }}>
					<ListItemText primary="Work Orders" />
				</ListItemButton>
			</List>
		</nav>
	);
}

function LeftNav({ isDrawerOpen }: { isDrawerOpen: boolean }) {
	const adminVisible = useAdminVisible();
	const { user } = useUserStore((state) => ({ user: state.user }));
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const drawerWidth = isDrawerOpen ? 240 : 0;

	const handleNavItemClick = (pathName: string) => {
		navigate(pathName);
	};

	return (
		<Drawer
			variant="permanent"
			anchor="left"
			open={isDrawerOpen}
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', overflowX: 'hidden' },
			}}>
			<Toolbar />
			<Box
				sx={{
					overflow: 'auto',
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					pt: 3,
				}}>
				{user?.isExternalPartner ? (
					<WorkOrderButton handleClick={() => handleNavItemClick('/studio/work-orders')} />
				) : (
					<nav aria-label="top navigation items">
						<List data-cy="leftNav" sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
							{studioNavItems.map((navItem) => (
								<ListItemButton
									key={navItem.name}
									selected={navItem.pathName === pathname}
									onClick={() => handleNavItemClick(navItem.pathName)}
									sx={{ px: 2, py: 1 }}>
									<ListItemIcon>{navItem.icon}</ListItemIcon>
									<ListItemText primary={navItem.text} />
								</ListItemButton>
							))}
							<TestProgramsNavButton pathname={pathname} handleNavItemClick={handleNavItemClick} />
						</List>
					</nav>
				)}
				{adminVisible && (
					<Box>
						<Divider />
						<WorkOrderButton handleClick={() => handleNavItemClick('/studio/work-orders')} />
						<Divider />
						<nav aria-label="admin navigation items">
							<List data-cy="adminNav" sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
								<ListItemButton onClick={() => handleNavItemClick(adminRoute)} sx={{ px: 2, py: 1 }}>
									<ListItemText primary="Go to Admin" />
								</ListItemButton>
							</List>
						</nav>
					</Box>
				)}
				<nav aria-label="bottom info">
					<List sx={{ display: 'flex', flexDirection: 'column', p: 0 }}>
						<ListItem>
							{user?.isZitaraAdmin ? (
								<ListItemText primary={user?.fullName} secondary={user?.currentUserGroup?.name || user?.customer} />
							) : (
								<ListItemText primary={user?.fullName} secondary={user?.customer} />
							)}
						</ListItem>
						<Divider />
						<ListItem alignItems="center" sx={{ justifyContent: 'center', gap: 2 }}>
							<AccountMenu />
							<IconButton aria-label="support" component="a" href="mailto:support@zitara.com?subject=Studio%20Support">
								<Help />
							</IconButton>
						</ListItem>
					</List>
				</nav>
			</Box>
		</Drawer>
	);
}

export { LeftNav };
