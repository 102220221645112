import { Alert, Portal, Snackbar } from '@mui/material';
import { useState } from 'react';
import getErrorMessage from 'util/handle-catch-error';
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import { usePatchWorkOrder } from 'api/work-order/use-patch-work-order';
import { useUserStore } from 'user-store';

export default function MarkAsCompleteButton({ id, disabled, ...props }) {
	const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
	const [isError, setIsError] = useState(false);
	const [message, setMessage] = useState('');
	const patchWorkOrder = usePatchWorkOrder();
	const { user } = useUserStore();

	const handleSubmit = async () => {
		setIsSnackbarOpen(false);
		setIsError(false);
		setMessage('');
		try {
			await patchWorkOrder.mutateAsync({ id: id, completedBy: user?.id });
			setIsSnackbarOpen(true);
			setIsError(false);
			setMessage('Task marked as complete!');
		} catch (err: unknown) {
			const message = getErrorMessage(err);
			setIsSnackbarOpen(true);
			setIsError(true);
			setMessage(message);
		}
	};

	const handleCloseSnackbar = () => {
		setIsSnackbarOpen(false);
	};

	return (
		<>
			<GridActionsCellItem {...props} disabled={disabled} onClick={handleSubmit} />
			<Portal>
				<Snackbar
					open={isSnackbarOpen}
					autoHideDuration={3000}
					onClose={handleCloseSnackbar}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
					<Alert onClose={handleCloseSnackbar} severity={isError ? 'error' : 'success'} sx={{ width: '100%' }}>
						{message}
					</Alert>
				</Snackbar>
			</Portal>
		</>
	);
}
